import React, { useState } from "react";
import { useContext } from "react";
import SearchContext from "../store/search-context";
import { Modal } from "react-rainbow-components";
import { Calendar } from "react-rainbow-components";
import formatDate from "../function/format-date";
import dateWrapper from "../function/date-wrapper";

const CalendarHeader = () => {
  const srcCtx = useContext(SearchContext);
  const [isModal, setIsModal] = useState(false);
  const modalHandler = () => {
    setIsModal(!isModal);
  };
  const [date, setDate] = useState(
    formatDate(new Date(srcCtx.data), "YYYY-mm-dd")
  );
  const dateHandler = (data) => {
    console.log("DATA HANDLER", date);
    let datex = formatDate(data, "YYYY-mm-dd");
    setDate(datex);
    srcCtx.sendData(datex);
    setIsModal(false);
  };
  const onClose = () => {
    srcCtx.sendData(date);
    setIsModal(false);
  };
  const today = new Date();
  return (
    <>
      <div onClick={modalHandler}>


        <span className="text-small d-none d-md-block">
         <img src={process.env.PUBLIC_URL + "calendar.svg"} className="icon-header" />
          {srcCtx.data != "" ? formatDate(srcCtx.data).slice(0, 5) : "--"}
        </span>
        <span className="text-small d-block d-md-none">
         <img src={process.env.PUBLIC_URL + "calendar.svg"} className="icon-header" />
        </span>
      </div>

      <Modal
        style={{ padding: "1.5rem" }}
        isOpen={isModal}
        onRequestClose={onClose}
      >
        <Calendar
          value={date}
          onChange={(e) => {
            dateHandler(e);
          }}
          minDate={today }
        />
      </Modal>
    </>
  );
};
export default CalendarHeader;
