import { Context } from "../../store/intl-context";
import { useContext } from "react";
const LanguageSelector = () => {
  const { language_cookie: cookie, setCookie } = useContext(Context);
  const setIt = () => {
    setCookie("it");
  };
  const setEn = () => {
    setCookie("en");
  };
  return (
    <div style={{ gap: "1rem" }} className="d-flex flex-row">
      <div
        style={{ cursor: "pointer", fontWeight: cookie === "it" ? 700 : 300 }}
        onClick={setIt}
      >
        IT
      </div>
      <div
        style={{ cursor: "pointer", fontWeight: cookie === "en" ? 700 : 300 }}
        onClick={setEn}
      >
        EN
      </div>
    </div>
  );
};
export default LanguageSelector;
