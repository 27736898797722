import { min } from "date-fns";
import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-rainbow-components";
import plus from "./add.svg";
import minus from "./minus.svg";

import UnderInput from "./underInput/UnderInput";
const Occupazione = (props) => {
  const inizializzato = {
    adulti: 1,
    bambini: 0,
    eta: [],
    datiCamera: { prezzo: 0 },
  };
  const [stanze, setStanze] = useState([
    { adulti: 2, bambini: 0, eta: [], datiCamera: { prezzo: 0 } },
  ]);
  useEffect(() => {
    console.log("STANZE INIZIALIZZATE", stanze);
  }, [stanze]);
  const setAdulti = (index, val) => {
    const old = [...stanze];
    old[index].adulti = val;
    setStanze(old);
  };

  const setBambini = (index, val) => {
    console.log("SET BAMBINI BUGGATO", val, index);
    const old = [...stanze];
    console.log("OLD BUG", old);
    if (old[index].bambini > val) {
      old[index].eta.pop();
    } else {
      old[index].eta.push(0);
    }
    old[index].bambini = val;

    setStanze(old);
  };
  const setEta = (index, val, subIndex) => {
    const old = [...stanze];
    old[index].eta[subIndex] = val;
    setStanze(old);
  };

  const removeStanza = () => {
    const old = [...stanze];

    old.pop();
    setStanze(old);
  };
  const addStanza = () => {
    const old = [...stanze];
    old.push({ ...inizializzato });
    setStanze(old);
  };
  useEffect(() => {
    if (props.stanze) {
      console.log("SETTO LE STANZON");
      setStanze(props.stanze);
    }
  }, [props.stanze]);
  if (props.mode === 1) {
    return (
      <>
        <Modal onRequestClose={props.close} isOpen={true}>
          <div
            onClick={() => {
              props.close ? props.close() : console.log("Chiudi!");
            }}
          ></div>
          <div
            className="container "
            style={{
              backgroundColor: "white",
              maxHeight: "100%",
              zIndex: 1100,
            }}
          >
            <hr />
            <div className="d-flex justify-content-center align-items-center pt-1 ">
              <div className="d-inline-flex col-6 justify-content-start pt-0 pl-0">
                <b>Camere</b>
              </div>
              <div className="d-inline-flex col-6 justify-content-center align-items-center">
                <img
                  className={stanze.length <= 1 ? "ddButton" : ""}
                  width={30}
                  onClick={stanze.length <= 1 ? null : removeStanza}
                  src={minus}
                />
                <b className="color-primary">{stanze.length}</b>
                <img
                  className={stanze.length >= 4 ? "ddButton" : ""}
                  width={30}
                  onClick={stanze.length >= 4 ? null : addStanza}
                  src={plus}
                ></img>
              </div>
            </div>
            <hr />
            {stanze.map((stanza, i) => (
              <div className=" pt-1">
                <div className="d-flex">
                  <b>Camera {i + 1}</b>
                </div>
                <div className="d-flex flex-column pt-1">
                  <div className="d-flex">
                    <div className="d-inline-flex col-6 justify-content-start pt-0 pl-0">
                      Adulti:
                    </div>
                    <div className="col-6 d-inline-flex align-items-center justify-content-center">
                      <img
                        width={30}
                        className={stanza.adulti <= 1 ? "ddButton" : ""}
                        onClick={() => {
                          if (stanza.adulti > 1)
                            setAdulti(i, stanza.adulti - 1);
                        }}
                        src={minus}
                      />
                      <b className="color-primary">{stanza.adulti}</b>
                      <img
                        width={30}
                        className={stanza.adulti >= 4 ? "ddButton" : ""}
                        onClick={() => {
                          if (stanza.adulti < 4) {
                            setAdulti(i, stanza.adulti + 1);
                          }
                        }}
                        src={plus}
                      />
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="d-inline-flex col-6 justify-content-start pt-0 pl-0">
                      Bambini:
                    </div>
                    <div className="col-6 d-inline-flex align-items-center justify-content-center">
                      <img
                        width={30}
                        className={stanza.bambini <= 0 ? "ddButton" : ""}
                        onClick={() => {
                          if (stanza.bambini >= 1)
                            setBambini(i, stanza.bambini - 1);
                        }}
                        src={minus}
                      />
                      <b className="color-primary">{stanza.bambini}</b>
                      <img
                        width={30}
                        className={stanza.bambini >= 2 ? "ddButton" : ""}
                        onClick={() => {
                          if (stanza.bambini < 2)
                            setBambini(i, stanza.bambini + 1);
                        }}
                        src={plus}
                      />
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            ))}

            <div
              onClick={() => {
                props.submit(stanze);
                props.close();
              }}
              style={{ color: "white" }}
              className="col-12 pl-0 pr-0 mtsh-1 mb-1 text-center btn btn-base"
            >
              Conferma
            </div>
          </div>
        </Modal>
      </>
    );
  } else if (props.mode === 2) {
    return (
      <>
        <UnderInput close={props.close}>
          <div
            onClick={() => {
              props.close ? props.close() : console.log("Chiudi!");
            }}
          ></div>
          <div
            className="container "
            style={{
              backgroundColor: "white",
              maxHeight: "100%",
              zIndex: 1100,
            }}
          >
            <hr />
            <div className="d-flex justify-content-center align-items-center pt-1 ">
              <div className="d-inline-flex col-6 justify-content-start pt-0 pl-0">
                <b>Camere</b>
              </div>
              <div className="d-inline-flex col-6 justify-content-center align-items-center">
                <img
                  className={stanze.length <= 1 ? "ddButton" : ""}
                  width={30}
                  onClick={stanze.length <= 1 ? null : removeStanza}
                  src={minus}
                />
                <b className="color-primary">{stanze.length}</b>
                <img
                  className={stanze.length >= 4 ? "ddButton" : ""}
                  width={30}
                  onClick={stanze.length >= 4 ? null : addStanza}
                  src={plus}
                ></img>
              </div>
            </div>
            <hr />
            {stanze.map((stanza, i) => (
              <div className=" pt-1">
                <div className="d-flex">
                  <b>Camera {i + 1}</b>
                </div>
                <div className="d-flex flex-column pt-1">
                  <div className="d-flex">
                    <div className="d-inline-flex col-6 justify-content-start pt-0 pl-0">
                      Adulti:
                    </div>
                    <div className="col-6 d-inline-flex align-items-center justify-content-center">
                      <img
                        width={30}
                        className={stanza.adulti <= 1 ? "ddButton" : ""}
                        onClick={() => {
                          if (stanza.adulti > 1)
                            setAdulti(i, stanza.adulti - 1);
                        }}
                        src={minus}
                      />
                      <b className="color-primary">{stanza.adulti}</b>
                      <img
                        width={30}
                        className={stanza.adulti >= 4 ? "ddButton" : ""}
                        onClick={() => {
                          if (stanza.adulti < 4) {
                            setAdulti(i, stanza.adulti + 1);
                          }
                        }}
                        src={plus}
                      />
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="d-inline-flex col-6 justify-content-start pt-0 pl-0">
                      Bambini:
                    </div>
                    <div className="col-6 d-inline-flex align-items-center justify-content-center">
                      <img
                        width={30}
                        className={stanza.bambini <= 0 ? "ddButton" : ""}
                        onClick={() => {
                          if (stanza.bambini >= 1)
                            setBambini(i, stanza.bambini - 1);
                        }}
                        src={minus}
                      />
                      <b className="color-primary">{stanza.bambini}</b>
                      <img
                        width={30}
                        className={stanza.bambini >= 2 ? "ddButton" : ""}
                        onClick={() => {
                          if (stanza.bambini < 2)
                            setBambini(i, stanza.bambini + 1);
                        }}
                        src={plus}
                      />
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            ))}

            <div
              onClick={() => {
                props.submit(stanze);
                props.close();
              }}
              style={{ color: "white" }}
              className="col-12 pl-0 pr-0 mtsh-1 mb-1 text-center btn btn-base"
            >
              Conferma
            </div>
          </div>
        </UnderInput>
      </>
    );
  } else if (props.mode === 3) {
    return (
      <div
        className="container "
        style={{
          backgroundColor: "white",
          maxHeight: "100%",
        }}
      >
        <div className="d-flex justify-content-center align-items-center pt-3 pb-3 bb-1-s">
          <div className="d-inline-flex col-6">Camere </div>
          <div className="d-inline-flex col-6 justify-content-center align-items-center">
            <img
              className={stanze.length <= 1 ? "ddButton" : ""}
              width={30}
              onClick={stanze.length <= 1 ? null : removeStanza}
              src={minus}
            />
            <b className="color-primary">{stanze.length}</b>
            <img
              className={stanze.length >= 4 ? "ddButton" : ""}
              width={30}
              onClick={stanze.length >= 4 ? null : addStanza}
              src={plus}
            ></img>
          </div>
        </div>
        {stanze.map((stanza, i) => (
          <div className="pt-3 pb-3 bb-1-s">
            <div className="d-flex">
              <strong>Camera {i + 1}</strong>
            </div>
            <div className="d-flex  flex-column">
              <div className="d-flex">
                <div className="col-6">Adulti:</div>
                <div className="col-6 d-inline-flex align-items-center justify-content-center">
                  <img
                    width={30}
                    className={stanza.adulti <= 1 ? "ddButton" : ""}
                    onClick={() => {
                      if (stanza.adulti > 1) setAdulti(i, stanza.adulti - 1);
                    }}
                    src={minus}
                  />
                  <b className="color-primary">{stanza.adulti}</b>
                  <img
                    width={30}
                    className={stanza.adulti >= 4 ? "ddButton" : ""}
                    onClick={() => {
                      if (stanza.adulti < 4) {
                        setAdulti(i, stanza.adulti + 1);
                      }
                    }}
                    src={plus}
                  />
                </div>
              </div>
              <div className="d-flex">
                <div className="col-6">Bambini:</div>
                <div className="col-6 d-inline-flex align-items-center justify-content-center">
                  <img
                    width={30}
                    className={stanza.bambini <= 0 ? "ddButton" : ""}
                    onClick={() => {
                      if (stanza.bambini >= 1)
                        setBambini(i, stanza.bambini - 1);
                    }}
                    src={minus}
                  />
                  <b className="color-primary">{stanza.bambini}</b>
                  <img
                    width={30}
                    className={stanza.bambini >= 2 ? "ddButton" : ""}
                    onClick={() => {
                      if (stanza.bambini < 2) setBambini(i, stanza.bambini + 1);
                    }}
                    src={plus}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
        <div
          onClick={() => {
            props.submit(stanze);
          }}
          style={{ color: "white" }}
          className="col-12 pl-0 pr-0 mtsh-1 mb-1 text-center btn btn-base"
        >
          Conferma
        </div>
      </div>
    );
  }
};
export default Occupazione;
