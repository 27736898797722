import React from "react";
import Logo from "./icon512x512.png";
import LanguageSelector from "../LanguageSelector/LanguageSelector";
const LogoPart = (props) => {
  return (
    <>
      <div className="d-flex  flex-column justify-content-center align-items-center">
        <img width={150} src={Logo} />
        <div className="" style={{ lineHeight: "1.3", fontSize: "0.8em" }}>
          Best Golf è un prodotto di: <br />
          <b>Orange Artcom srl</b>
          <br />
          Via Girolamo Segato, 31
          <br />
          00147 - Rome - Italy
          <br />
        </div>
        <div
          className="mt-3"
          style={{ marginLeft: "19px", lineHeight: "1.3", fontSize: "0.8em" }}
        >
          I pacchetti golf sono gestiti da:
          <br />
          <b>Business Class Viaggi</b>
          <br />
          Autorizzazione regione
          <br />
          4046 del 26/06/2008
          <br />
        </div>
		  <div className="mt-3">
		  <LanguageSelector/>
		  </div>
      </div>
    </>
  );
};
export default LogoPart;
