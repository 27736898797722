import React from "react";
import { FormattedMessage } from "react-intl";
const FollowUs = () => {
  const facebook = (
    <i class="fa fa-facebook-square fa-2x" aria-hidden="true"></i>
  );
  const instagram = <i class="fa fa-instagram fa-2x" aria-hidden="true"></i>;
  const linkedin = <i class="fa fa-linkedin fa-2x" aria-hidden="true"></i>;
  const socials = [
    { icon: facebook, link: "https://www.facebook.com/Italybestgolf/" },
    {
      icon: linkedin,
      link: "https://www.linkedin.com/in/italy-best-golf-9426a7167/",
    },
    { icon: instagram, link: "https://www.instagram.com/italybestgolf/" },
  ];
  return (
    <>
      <div className="d-block text-center  ">
        <h5 className="labelSmall"><FormattedMessage id="seguici" /></h5>
        {socials.map((val) => {
          return (
            <a style={{ color: "white" }} href={val.link} className="m-2">
              {val.icon}
            </a>
          );
        })}
      </div>
    </>
  );
};

export default FollowUs;
