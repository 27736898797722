import React, { useState, useEffect } from "react";
import SearchContext from "../store/search-context";
import { useContext } from "react";
import { Modal } from "react-rainbow-components";

import AdvancedTextSearchGolf from "./SearchBar/AdvancedTextSearchGolf.jsx";
import AdvancedTextSearch from "./SearchBar/AdvancedTextSearch";
import AdvancedTextSearchGare from "./SearchBar/AdvancedTextSearchGare";
import SearchGare from "../HomeComponents/SearchGolf/SearchGare";

import useGetAllPacchetti from "../../hooks/get-all-pacchetti";

import useGetGolf from "../../hooks/use-get-golf";

import useGetAllGare from "../../hooks/get-all-gare";
const TermHeader = (props) => {
  const srcCtx = useContext(SearchContext);

  const gare = useGetAllGare();
  const pacchetti = useGetAllPacchetti();
  const campi = useGetGolf();

  const [isModal, setIsModal] = useState(false);

  const modalHandler = () => {
    setIsModal(!isModal);
  };
  const [lonSearch, setLonSearch] = useState();
  const liftLon = (lon) => {
    console.log("LON SEARCH", lon);
    setLonSearch(lon);
  };
  const [latSearch, setLatSearch] = useState();
  const liftLat = (lat) => {
    console.log("LAT SEARCH", lat);
    setLatSearch(lat);
  };

  const [place, setPlace] = useState("");

  const submit = () => {
    if (latSearch != srcCtx.lat && lonSearch != srcCtx.lon) {
      srcCtx.sendLat(latSearch);
      srcCtx.sendLon(lonSearch);
    }
    srcCtx.send(place);
    setIsModal(false);
  };

  const PlaceHandler = (posto) => {
    setPlace(posto);
    console.log("POSTO", posto);
  };
  useEffect(() => {
    if (place != null && place != undefined && place != "") {
      submit();
    }
  }, [latSearch, lonSearch, place]);
  return (
    <>
      <div onClick={modalHandler} className="pl-0 pl-md-3">
        <span className="text-small d-none d-md-block">
          {srcCtx.term != "" ? srcCtx.term : "Scegli la zona "}
        </span>
        <span className="text-small d-block d-md-none">
         <img src={process.env.PUBLIC_URL + "golf.png"} alt="" className="icon-header"/>
        </span>
      </div>
      <Modal isOpen={isModal} onRequestClose={submit}>
        {props.idCanale === 3 && (
          <AdvancedTextSearchGolf
            header={true}
            toSearch={campi}
            liftLon={liftLon}
            liftLat={liftLat}
            onPick={PlaceHandler}
          />
        )}
        {props.idCanale === 8 && (
          <AdvancedTextSearch
            liftLon={liftLon}
            liftLat={liftLat}
            onPick={PlaceHandler}
            header={true}
            modex={2}
            toSearch={pacchetti}
          />
        )}
        {props.idCanale === 9 && (
          <AdvancedTextSearchGare
            toSearch={gare}
            header={true}
            onPick={PlaceHandler}
            liftLon={liftLon}
            liftLat={liftLat}
          />
        )}
      </Modal>
    </>
  );
};
export default TermHeader;
