import { React, useContext, useEffect } from "react";
import "./footer.css";

import LogoPart from "./FooterComponents/LogoPart";
import UsefulLinks from "./FooterComponents/UsefulLinks";
import FollowUs from "./FooterComponents/FollowUs";
import FormFooter from "./FooterComponents/FormFooter";
import Payments from "./FooterComponents/Payments";
import TimeContext from "../store/time-context";

import Partners from "./FooterComponents/partners/Partners";

import { Context } from "../store/intl-context";

const Footer = (props) => {
  const timeCtx = useContext(TimeContext);
  return (
    <>
      {timeCtx.widget ? (
        ""
      ) : (
        <>
          <div id="foot" className="bgFooter " style={{ color: "white" }}>
            <div className="footerGrid">
              <div>
                <LogoPart />
              </div>
              <div>
                <UsefulLinks />
              </div>
              <div>
                <FollowUs />
              </div>
              <div>
                <FormFooter />
              </div>
              <div>
                <Payments />
              </div>
            </div>
            <div className="copyright ">
              <h4 className="text-white" style={{ margin: 0 }}>
                © 2022 Orange Artcom srl - P.I. 12360351006 - All rights reserved
              </h4>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default Footer;
