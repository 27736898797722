import React from "react";

import Slider from "react-slick";
import immpresa from "./golf-impresa.png";
import pertutti from "./golf-per-tutti.png";
import infordata from "./infordata.png";
import italyholes from "./italy-9-holes.png";
import orange from "./orange-bianco.png";
import pga from "./pga-logo.png";
import yamaha from "./yamaha-logo.png";
import logo from "../icon512x512.png";
import mrh from "./mhr.png";
import vero from "./Logo-vero-01.jpg";
const Partners = () => {
  const sponsor = [orange, yamaha, vero];
  const partner = [pga, immpresa, italyholes, pertutti];
  const mediaPartner = [mrh];
  const officialPartner = [infordata];
  return (
    <>
      <div
        style={{ rowGap: "3rem" }}
        className="container  d-flex flex-column justify-content-center align-items-center  "
      >
        <div className=" pt-3 justify-content-center text-center align-items-center">
          <text className=" mb-3 d-flex justify-content-center align-items-center partners text-white">SPONSOR</text>
            {" "}
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ flexWrap: "wrap", gap: "1rem" }}
              >
                {sponsor.map((val) => (
                  <img style={{ width: "150px" }} alt="logos" src={val} />
                ))}
              </div>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <text className="partners text-white mb-3">PARTNER</text>
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ flexWrap: "wrap" }}
          >
            {partner.map((val) => (
              <img
                style={{ width: window.innerWidth > 1000 ? "auto" : "150px" }}
                src={val}
              />
            ))}
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <text className="partners text-white mb-3">MEDIA PARTNER</text>
          <div className="d-flex">
            {mediaPartner.map((val) => (
              <img src={val} />
            ))}
          </div>
        </div>
        <div className="d-flex mb-5 flex-column justify-content-center align-items-center text-white">
          <text className="text-white mb-3" >OFFICIAL TECHNICAL PARTNER</text>{" "}
          <div className="d-flex">
            {officialPartner.map((val) => (
              <img src={val} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default Partners;
