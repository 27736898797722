import React, { useEffect } from "react";
import Footer from "./footer";
import Header from "./header";
import PageHelmet from "../Helmet/PageHelmet";
const Wrapper = (props) => {
  const title = props.location.pathname
    .substring(1)
    .toUpperCase()
    .replace(/-/g, " ")
    .replace("/", " | ");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [title]);
  return (
    <>
      <PageHelmet title={title != "" ? title : "HOME"}></PageHelmet>
      {!props.location.pathname.includes('/pacchetto-dinamico') && <Header />}
		  <div> {props.children}</div>
      {props.location.pathname != "/campi-da-golf" &&
       !props.location.pathname.includes('totem') && 
        props.location.pathname != "/mappa" &&
        !props.location.pathname.includes("/prenotazionestruttura") &&
        !props.location.pathname.includes("/prenotazione-campo") &&
        !props.location.pathname.includes("/prenotazione-pacchetto") &&
        props.location.pathname != "/gare" &&
        props.location.pathname != "/pacchetti" &&
        !props.location.pathname.substring().includes("/time") &&
        !props.location.pathname.includes('/pacchetto-dinamico') && <Footer />}
    </>
  );
};

export default Wrapper;
