import React from "react";
import Gdpr from "../GDPR/Gdpr.jsx";
import { FormattedMessage } from "react-intl";
const FormFooter = () => {
  return (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <h5 className="labelSmall"><FormattedMessage id="iscrivitinewsletter" /></h5>
        <text
          style={{
            fontSize: "13px",
            paddingLeft: "15px",
            paddingTop: "15px",
            paddingBottom: "15px",
          }}
        >
          Per ricevere via e-mail e/o WhatsApp informazioni sulle gare della
          settimana, sui pacchetti vacanze, sugli eventi e le promozioni Best
          Golf.
        </text>
        <form className="d-flex flex-column formFooter  justify-content-center align-items-center">
          <div className="d-flex">
            <div className="col-6">
              <input placeholder="Nome" type="text" />
            </div>
            <div className="col-6">
              <input placeholder="Cognome" type="text" />
            </div>
          </div>
          <div className="d-flex mt-3">
            <div className="col-6">
              <input placeholder="Telefono" type="text" />
            </div>
            <div className="col-6">
              <input placeholder="Email" type="text" />
            </div>
          </div>
          <div className="col-12 mt-3">
            <Gdpr small={true}>
                <button className="btn btn-success color-white">
                  Iscrivimi
                </button>
            </Gdpr>
          </div>
        </form>
      </div>
    </>
  );
};
export default FormFooter;
