import React, { useState, useEffect, useContext } from "react";
import SearchContext from "../components/store/search-context";
import AuthContext from "../components/store/auth-context";
import axios from "axios";
const useGetPacchetti = (
  preTipologia = "",
  dataInizio,
  roomsQuery = JSON.stringify([
    { adults: 1, extrabed: false, age: "", cot: false },
  ]),
  setLoadingMio,
  latTs,
  lonTs,
  distance=39.0625
) => {
const authCtx=useContext(AuthContext)
  const [pacchetti, setPacchetti] = useState({ data: [] });
  useEffect(() => {
		  if(typeof(distance)==='number'){
		  
    var FormData = require("form-data");
    var data = new FormData();
    data.append(
      "id_tipologia_pacchetto",
      preTipologia != null && preTipologia != undefined ? preTipologia : 0
    );
    data.append("prezzo_min", "");
    data.append("prezzo_max", "");
    data.append("ssid_utente_creazione", "");
    data.append("ssid_utente_modifica", "");
    data.append("data_inizio", "");
    data.append("data_fine", "");
    data.append("regione", "");
    data.append("stanze", roomsQuery);
    data.append("itemsperpage", "200");
    data.append("lat", latTs);
    data.append("lon", lonTs);
    data.append("distance", distance);
    var config = {
      method: "post",
      url: process.env.REACT_APP_API_ENDPOINT+"/turismoadmin/searchpacchetti",
      headers: {
        Accept: "application/json",
				  'Authorization':"Bearer "+authCtx.session_token
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log("RESPONSE DEI PACCHETTI", response.data);
        const temp = { ...response.data, ["tipo"]: "Struttura" };
        setPacchetti(temp);
        setLoadingMio(true);
      })
      .catch(function (error) {
        console.log(error);
	  });
		  }
  }, [preTipologia, dataInizio, roomsQuery,latTs,lonTs,distance]);
  return pacchetti;
};
export default useGetPacchetti;
