import App from ".";

import reportWebVitals from "./reportWebVitals";
import React, { Suspense, lazy, useState, useEffect, useContext } from "react";
import "./components/style/index.css";

import "./components/style/bootstrap.css";
import "./components/style/bootstrap-grid.css";

import "../node_modules/font-awesome/css/font-awesome.min.css";
import ReactDOM from "react-dom";
import "./components/style/mysass.scss";
import "./components/style/App.css";
import { LastLocationProvider } from "react-router-last-location";

import "./components/style/main.scss";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Wrapper from "./components/common/wrapper";
import AuthContext from "./components/store/auth-context";
import { AuthContextProvider } from "./components/store/auth-context";
import { SearchContextProvider } from "./components/store/search-context";
import { TimeContextProvider } from "./components/store/time-context";
import { IntlProviderWrapper } from "./components/store/intl-context";
import { CartContextProvider } from "./components/store/cart-context";

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import { initializeFirebase } from "./push-notification";

if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

const Root = () => {

  const Index = lazy(() => import("./components/Index"));

  const Contatti = lazy(() => import("./components/Contatti/Contatti.jsx"));

  const PacchettoSingolo = lazy(() => import("./components/pacchetto"));

  const Campo = lazy(() => import("./components/campo"));
  const PacchettoDinamico = lazy(() => import("./components/PacchettoDinamico"));

  const Tabellone = lazy(() => import("./components/time/Tabellone"));

  const Gara = lazy(() => import("./components/gara"));

  const mainB = lazy(() => import("./components/backoffice/mainB"));

  const CampiGolf = lazy(() => import("./components/CampiGolf"));

  const PrenotazioneCampo = lazy(() =>
    import("./components/CampiGolf/PrenotazioneGolf")
  );

  const RiepilogoCampo = lazy(() =>
    import("./components/CampiGolf/RiepilogoGolf")
  );
  const TestPage = lazy(() => import("./components/testPage"));
  const PacketPage = lazy(() => import("./components/PacketPage"));
  const PrenotazionePacchetto = lazy(() =>
    import("./components/common/BookingEngine/FormWizard")
  );
  const DummyAnagrafica = lazy(() => import("./components/DummyAnagrafica"));
  const LostPassword = lazy(() => import("./components/Login/LostPassword"));
  const Homepage = lazy(() => import("./components/Homepage"));

  const TimePage = lazy(() => import("./components/time/TimePage"));
  const TimeCalendarPage = lazy(() =>
    import("./components/time/TimeCalendarPage")
  );
  const TimeServiziPage = lazy(() =>
    import("./components/time/TimeServiziPage")
  );
  const TimeWherePage = lazy(() => import("./components/time/TimeWherePage"));

  const TimeListPage = lazy(() => import("./components/time/TimeListPage"));

  const TimeGolfPage = lazy(() => import("./components/time/TimeGolfPage"));

  const WidgetPage = lazy(() => import("./components/time/WidgetPage"));

  const RiepilogoPage = lazy(() => import("./components/time/RiepilogoPage"));

  const TimeTransfer = lazy(() => import("./components/time/TimeTransfer"));

  const ThankYouCircolo = lazy(()=> import("./components/time/ThankYouCircolo"));
  const ThankYouCheckout = lazy(()=> import("./components/time/ThankYouCheckout"));

  const AreaRiservata = lazy(() =>
    import("./components/areariservata/AreaRiservata")
  );
  const AreaRiservataEdit = lazy(() =>
    import("./components/areariservata/AreaRiservataEdit")
  );
  const Wip = lazy(() => import("./components/WIP"));

  const StructurePage = lazy(() =>
    import("./components/time/mappa/StructurePage")
  );
  const StructuraPage = lazy(() => import("./components/time/mappa/struttura"));

  const PrenotazioneStruttura = lazy(() =>
    import("./components/time/mappa/PrenotazioneStruttura")
  );

  const BookingEngine = lazy(() =>
    import("./components/common/BookingEngine/FormWizard")
  );
  const RegisterOperator = lazy(() =>
    import("./components/Login/RegisterOperator")
  );
  const GolfPage = lazy(() => import("./components/CampiGolf/GolfPage"));

  const carrello = lazy(() => import("./components/carrello"));

  const CalendarioGolf = lazy(() =>
    import("./components/CampiGolf/golfCalendar")
  );
  const Gare = lazy(() => import("./components/gare/GaraPage"));
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <BrowserRouter>
      <LastLocationProvider>
        <Suspense fallback={<div>LOADING</div>}>
          <Switch>
            <Wrapper>
              <Route exact path="/" component={Homepage} />
              <Route path="/pacchetto/:id" component={PacchettoSingolo} />
              <Route
                path="/prenotazione-pacchetto/:id"
                component={PrenotazionePacchetto}
              />
              <Route exact path="/golf-club/:id" component={Campo} />
              <Route path="/totem/:id" component={Tabellone} />
              <Route path="/gara/:id" component={Gara} />
              <Route path="/calendario-campo/:id" component={CalendarioGolf} />
              <Route
                path="/prenotazione-campo/:slug"
                component={PrenotazioneCampo}
              />
              <Route path="/gare" component={Gare} />
              <Route path="/riepilogo-campo" component={RiepilogoCampo} />
              <Route path="/backoffice" component={mainB} />
              <Route path="/404" component={Index} />
              <Route path="/campi-da-golf" component={GolfPage} />
              <Route path="/test" component={TestPage} />
              <Route path="/pacchetti" component={PacketPage} />
              <Route path="/dummy-anagrafica" component={DummyAnagrafica} />
              <Route exact path="/lost-password" component={LostPassword} />
              <Route path="/home" component={Homepage} />
            
              <Route path="/areariservata" component={AreaRiservata} />
              <Route path="/edit-profile" component={AreaRiservataEdit} />
              
              <Route path="/riepilogocarrello" component={carrello} />
              <Route path="/mappa" component={StructurePage} />
              <Route path="/struttura/:id" component={StructuraPage} />
              <Route
                path="/prenotazionestruttura/:id"
                component={PrenotazioneStruttura}
              />
              <Route path="/wip/:id" component={Wip} />
              <Route path="/registraoperatore" component={RegisterOperator} />

              <Route path="/contatti" component={Contatti} />

              <Route path="/thank-you-circolo/:id" component={ThankYouCircolo} />
              <Route path="/thank-you-checkout/:id" component={ThankYouCheckout} />


              <Route path="/time" component={TimePage}>
                <Route exact path="/time" component={TimePage} />
                <Route path="/time/calendar" component={TimeCalendarPage} />
                <Route path="/time/where" component={TimeWherePage} />
                <Route path="/time/transfer" component={TimeTransfer} />
                <Route path="/time/list" component={TimeListPage} />
                <Route path="/time/checkout" component={RiepilogoPage} />
                <Route path="/time/servizi/:id" component={TimeServiziPage} />
                <Route path="/time/widget/:uuid" component={WidgetPage} />
                <Route path="/time/golf/:slug" component={TimeGolfPage} />
              </Route>


              <Route exact path="/circolo/203/:id">
                <Redirect to="/golf-club/versilia-golf-resort-forte-dei-marmi" />
              </Route>
              <Route exact path="/circolo/46/:id">
                <Redirect to="/golf-club/croara-country-club" />
              </Route>
              <Route exact path="/scheda_circolo.php">
                <Redirect to="/golf-club/castelgandolfo-golf-club" />
              </Route>
              <Route exact path="/circolo/189/:id">
                <Redirect to="/golf-club/golf-club-centanni" />
              </Route>
              <Route exact path="/circolo/64/:id">
                <Redirect to="/golf-club/riviera-golf" />
              </Route>
              <Route exact path="/circolo/40/:id">
                <Redirect to="/golf-club/golf-club-fiuggi-1928" />
              </Route>
              <Route exact path="/circolo/59/:id">
                <Redirect to="/golf-club/golf-club-molino-del-pero" />
              </Route>

              <Route exact path="/pacchetto-dinamico/:id" component={PacchettoDinamico} />
              



            </Wrapper>
          </Switch>
        </Suspense>
      </LastLocationProvider>
    </BrowserRouter>
  );
};

ReactDOM.render(
  <AuthContextProvider>
    <SearchContextProvider>
      <CartContextProvider>
        <TimeContextProvider>
          <IntlProviderWrapper locale="it">
            <Root />
          </IntlProviderWrapper>
        </TimeContextProvider>
      </CartContextProvider>
    </SearchContextProvider>
  </AuthContextProvider>,
  document.getElementById("root")
);
// initializeFirebase();

serviceWorkerRegistration.unregister();
if (caches) {
  caches.keys().then(cacheNames => {
    cacheNames.forEach(cacheName => {
      caches.delete(cacheName);
    });
  }).then(() => {
    serviceWorkerRegistration.register();
  })
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
