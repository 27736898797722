import React, { useState, useEffect } from "react";
import Gdpr from "../common/GDPR/Gdpr";

const SignupForm = (props) => {
  const [emailInput, setEmailInput] = useState(props.email ? props.email : '');

  const [passwordInput, setPasswordInput] = useState("");
  const [codeInput, setCodeInput] = useState("");
  const [nomeInput, setNomeInput] = useState(props.nome ? props.nome : '');
  const [cognomeInput, setCognomeInput] = useState(props.cognome ? props.cognome : '');
  const [semaforoPassword, setSemaforoPassword] = useState(false);

  const nomeHandler = (event) => {
    setNomeInput(event.target.value);
  };
  console.log(nomeInput);
  const cognomeHandler = (event) => {
    setCognomeInput(event.target.value);
  };
  const passwordHandler = (event) => {
    setPasswordInput(event.target.value);
  };
  const emailHandler = (event) => {
    setEmailInput(event.target.value);
  };
  const codeHandler = (event) => {
    setCodeInput(event.target.value);
  };

  useEffect(() => {
    if (codeInput === passwordInput) {
      setSemaforoPassword(true);
    } else {
      setSemaforoPassword(false);
    }
  }, [codeInput, passwordInput]);

  const onSubmit = (event) => {
    event.preventDefault();
    const user = {
      email: emailInput,
      nome: nomeInput,
      cognome: cognomeInput,
      password: passwordInput,
      code: codeInput,
    };
    props.sendRegisterUser(user);
  };
  return (
    <>
      <form onSubmit={onSubmit}>
        <div class="mb-3">
          <label class="form-label" for="useremail">
            Email
          </label>
          <input
            required
            type="email"
            class="form-control"
            id="useremail"
            placeholder="La tua email"
            value={emailInput}
            onChange={emailHandler}
          />
        </div>
        <div class="mb-3">
          <label class="form-label" for="username">
            Nome
          </label>
          <input
            required
            type="text"
            class="form-control"
            id="username"
            value={nomeInput}
            placeholder="Il tuo nome"
            onChange={nomeHandler}
          />
        </div>
        <div class="mb-3">
          <label class="form-label" for="username">
            Cognome
          </label>
          <input
            required
            type="text"
            class="form-control"
            id="username"
            value={cognomeInput}
            placeholder="Il tuo cognome"
            onChange={cognomeHandler}
          />
        </div>
        <div class="mb-3">
          <label class="form-label" for="userpassword">
            Password
          </label>
          <input
            required
            type="password"
            class="form-control"
            id="userpassword"
            placeholder="Password"
            onChange={passwordHandler}
          />
        </div>
        <div class="mb-3">
          <label class="form-label" for="userpassword">
            Conferma Password
          </label>
          <input
            type="password"
            class="form-control"
            id="usercode"
            placeholder="Ripeti password"
            onChange={codeHandler}
            style={
              semaforoPassword === false && passwordInput.length > 0
                ? { backgroundColor: "red", color: "white" }
                : { backgroundColor: "#ffffff", color: "#000000" }
            }
          />
        </div>
        <div class="form-check">
          {/* <input
          required
            type="checkbox"
            class="form-check-input"
            id="auth-terms-condition-check"
          /> */}
        </div>
        <div class="mt-3 text-end d-flex justify-content-end">
          <Gdpr>
            <button
              class="btn btn-primary w-sm waves-effect waves-light"
              type="submit"
              style={{ width: "100%", backgroundColor: "#a91354" }}
              disabled={semaforoPassword === false ? true : false}
            >
              Registrati
            </button>
          </Gdpr>
        </div>
      </form>
    </>
  );
};
export default SignupForm;
