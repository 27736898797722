import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
const UsefulLinks = () => {
  const menu = [
    { nome: "Green Fee", link: "/campi-da-golf" },
    /*{ nome: "Gare", link: "/Gare" },*/
    { nome: "Vacanze Golf", link: "/pacchetti" },
    { nome: "Contatti", link: "/contatti" },
    { nome: "Accesso Circoli", link: "https://gestionale.italybestgolf.com?circoli" },
    { nome: "Accesso Travel", link: "https://gestionale.italybestgolf.com?travel" },
    { nome: "Termini e condizioni", link: "https://www.italybestgolf.com/doc_bg/2021-06-Condizioni-generali-prenotazione-acquisto-green-fee-e-gare.pdf" },
    { nome: "Privacy", link: "https://www.italybestgolf.com/doc_bg/2021-06-Informativa-estesa.pdf" }
  ];

  return (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <h5 className="labelSmall"><FormattedMessage id="linkutili" /></h5>
        {menu.map((val) => (
          <a className="p-1" style={{ color: "white" }} href={val.link} >
            {val.nome}
          </a>
        ))}
        <a href="https://www.iubenda.com/privacy-policy/67383170" style={{ color: "white" }} target="_blank" className="p-1 iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe " title="Cookie Policy ">Cookie</a>
      </div>
    </>
  );
};
export default UsefulLinks;
